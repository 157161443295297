<template>
  <div class="wrapper ">
    <ctheader></ctheader>
    <div class="supervise-container">
      <QueryConditions
        ref="queryConditions"
        :filterList="queryConditionsObj.filterList"
        :selectList="queryConditionsObj.selectList"
        :timeSelectList="queryConditionsObj.timeSelectList"
        @query="onQueryChange"
      >
        <div slot="actions">
          <el-button size="mini" type="primary" plain @click="add" class="btn">
            新增
          </el-button>
        </div>
      </QueryConditions>
      <TableData
        ref="table"
        :tableConfig="tableConfig"
        :tableData="tableData"
        :actionList="orderBtn"
        @editSupervise="editSupervise"
        @setSupervise="setSupervise"
        @copySupervise="copySupervise"
      ></TableData>
      <Pagination
        :total="total"
        :start.sync="filterRuleForm.start"
        :length.sync="filterRuleForm.length"
        @pagination="handleCurrentChange"
        ref="pagination"
      >
      </Pagination>
      <el-drawer
        :title="title"
        :visible.sync="drawer"
        :direction="direction"
        size="40%"
        :before-close="close"
      >
        <div class="drawer-div">
          <el-form ref="form" :model="form" label-width="80px" size="small">
            <el-form-item label="平台名称">
              <el-input v-model="form.name"></el-input>
            </el-form-item>
            <el-form-item label="负责人">
              <el-input v-model="form.user_name"></el-input>
            </el-form-item>
            <el-form-item label="联系方式">
              <el-input v-model="form.mobile"></el-input>
            </el-form-item>
            <el-form-item label="关联群组">
              <el-select
                v-model="form.supervise_id"
                placeholder="请选择"
                filterable
              >
                <el-option
                  v-for="item in marketGroupList"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="是否启用">
              <el-switch
                v-model="form.status"
                :active-value="1"
                :inactive-value="0"
              >
              </el-switch>
            </el-form-item>
            <el-form-item label="备注">
              <el-input v-model="form.remark" type="textarea"></el-input>
            </el-form-item>
          </el-form>
        </div>
        <div class="pop-button">
          <el-button @click="close">取 消</el-button>
          <el-button type="primary" @click="save">
            确 定
          </el-button>
        </div>
      </el-drawer>
      <el-drawer
        title="平台设置"
        :visible.sync="drawer_set"
        :direction="direction"
        size="40%"
        :before-close="close"
      >
        <div class="drawer-div" v-if="!title">
          <el-form ref="form" :model="form" label-width="80px" size="small">
            <el-form-item label="平台名称">
              <el-input v-model="form.name"></el-input>
            </el-form-item>
            <el-form-item label="负责人">
              <el-input v-model="form.user_name"></el-input>
            </el-form-item>
            <el-form-item label="联系方式">
              <el-input v-model="form.mobile"></el-input>
            </el-form-item>
            <el-form-item label="关联群组">
              <el-select
                v-model="form.supervise_id"
                placeholder="请选择"
                filterable
              >
                <el-option
                  v-for="item in marketGroupList"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="是否启用">
              <el-switch
                v-model="form.status"
                :active-value="1"
                :inactive-value="0"
              >
              </el-switch>
            </el-form-item>
            <el-form-item label="备注">
              <el-input v-model="form.remark" type="textarea"></el-input>
            </el-form-item>
          </el-form>
        </div>
        <div class="drawer-div" v-else>
          <el-form ref="form" :model="form" label-width="80px" size="small">
            <el-form-item label="平台标题">
              <el-input v-model="form.title"></el-input>
            </el-form-item>
            <el-form-item label="平台code">
              <el-input v-model="form.code"></el-input>
            </el-form-item>

            <el-form-item label="平台logo">
              <el-upload
                action="/api/upload/file"
                list-type="picture-card"
                :data="{ action: 'image' }"
                :on-remove="handleRemove"
                :on-success="handleAvatarSuccess"
                :file-list="fileList"
                :limit="1"
                :class="{ disUoloadSty: fileList.length != 0 }"
              >
                <span class="el-icon-plus"></span>
              </el-upload>
              <span class="span1">
                支持jpg/png,不大于2M
              </span>
            </el-form-item>
          </el-form>
        </div>
        <div class="pop-button">
          <el-button @click="close">取 消</el-button>
          <el-button type="primary" @click="save">
            确 定
          </el-button>
        </div>
      </el-drawer>
    </div>
  </div>
</template>
<script>
// import pagination from "@/components/pagination";
import ctheader from "../../components/ctheader";

import QueryConditions from "@/components/queryConditions";
import TableData from "@/components/tableData";
import Pagination from "@/components/new_pagination";

import query from "@/mixins/query";
import {
  superviseListQueryConditions,
  screenAdListTableConfig,
  screenAdStatisticsTableBtn,
} from "./config/index";
import { superviseListServe, marketGroupServe } from "./api";
import moment from "moment";

export default {
  name: "",
  components: { QueryConditions, TableData, Pagination, ctheader },
  mixins: [query],
  props: {},
  data() {
    return {
      queryConditionsObj: {},
      tableConfig: [],
      tableData: [],
      orderBtn: [],
      drawer: false,
      direction: "rtl",
      form: {},
      marketGroupList: [],

      gridForm: {
        action: "market.list",
        start: 0,
        length: 99999,
      },
      filterRuleForm: {
        action: "lists",
        start: 0,
        length: 10,
      },
      title: "新增",
      drawer_set: false,
      fileList: [],
    };
  },
  computed: {},
  watch: {},
  mounted() {
    this.queryConditionsObj = superviseListQueryConditions;
    this.tableConfig = screenAdListTableConfig;
    this.orderBtn = screenAdStatisticsTableBtn;
    this.filterRequestWrapper();
    this.getMarketGroupList();
    // console.log(this.queryConditionsObj.filterSelect);
  },
  methods: {
    add() {
      this.title = "新增";
      this.drawer = true;
      this.form.action = "add";
      this.getMarketGroupList();
    },
    async filterRequestWrapper() {
      const { code, data, total } = await superviseListServe(
        this.filterRuleForm
      );
      if (code == 0) {
        this.tableData = data.map((item) => {
          return {
            ...item,
            created_at_parse: moment(item.created_at * 1000).format(
              "YYYY-MM-DD HH:mm:ss"
            ),
            status_parse: item.status == 1 ? "启用" : "禁用",
          };
        });
        this.total = total;
      }
    },
    // 获取市场群组下拉
    async getMarketGroupList() {
      const { code, data } = await marketGroupServe({
        action: "lists",
        start: 0,
        length: 9999,
      });
      if (code == 0) {
        this.marketGroupList = data;
        this.queryConditionsObj.selectList[0].filterType = data.map((item) => {
          return {
            label: item.name,
            value: item.id,
          };
        });
      }
    },

    async save() {
      const { code, msg } = await superviseListServe(this.form);
      if (code == 0) {
        this.$message.success(msg);
        this.drawer = false;
        this.drawer_set = false;

        this.filterRequestWrapper();
        this.form = {};
      }
    },
    close() {
      this.drawer = false;
      this.drawer_set = false;

      this.form = {};
    },
    editSupervise(val) {
      this.title = "编辑";
      this.drawer = true;
      this.form = val;
      this.form.action = "edit";
      this.getMarketGroupList();
    },
    setSupervise(val) {
      this.form = {
        action: "setting.save",
        id: val.id,
        code: val.code,
        title: val.title,
        logo: val.logo,
      };
      this.fileList = val.logo ? [{ name: "1", url: val.logo }] : [];
      this.drawer_set = true;
    },
    copySupervise(val) {
      console.log(val);
      let url = val.supervise_url; // 后台接口返回的链接地址
      let inputNode = document.createElement("input"); // 创建input
      inputNode.value = url; // 赋值给 input 值
      document.body.appendChild(inputNode); // 插入进去
      inputNode.select(); // 选择对象
      document.execCommand("Copy"); // 原生调用执行浏览器复制命令
      inputNode.className = "oInput";
      inputNode.style.display = "none"; // 隐藏
      this.$message.success("复制成功");
    },
    handleAvatarSuccess(res) {
      console.log(res);
      if (res.code == 0) {
        this.form.logo = res.data.url_http;
        this.fileList = [{ name: "1", url: res.data.url_http }];
      } else {
        this.$message.warning(res.msg);
      }
    },
    handleRemove() {
      this.fileList = [];
      this.form.logo = "";
    },
  },
};
</script>
<style lang="less">
.supervise-container {
  background: #fff;
  min-height: 100vh;
  box-shadow: 0 0 10px 0 #e0e3e8;
  margin-left: 18px;
  margin-right: 18px;
  margin-bottom: 18px;
  border-radius: 4px;
  padding: 24px;

  .pop-button {
    // margin: 200px auto 0;
    bottom: 20px;
    position: absolute;
    width: 100%;
    display: flex;
    padding: 0 12px;
    .el-button {
      width: 100%;
    }
  }
  .drawer-div {
    padding: 24px;
    .el-input,
    .el-select,
    .el-textarea__inner {
      width: 500px;
    }
  }
  .disUoloadSty .el-upload--picture-card {
    display: none; /* 上传按钮隐藏 */
  }
}
</style>
