//信发管理——信发屏管理列表查询
export const superviseListQueryConditions = {
  timeSelectList: [
    {
      filterRuleFormValue: "timeQueryType",
      beginTime: "begin_time",
      endTime: "end_time",
      format: "timestamp",
    },
  ],
  selectList: [
    {
      name: "全部群组",
      filterRuleFormValue: "market_id",
      filterType: [],
      filterable: true,
    },
  ],

  filterList: [
    {
      name: "监管名称",
      filterRuleFormValue: "keyword",
      width: "320px",
    },
  ],
};

//信发管理——信发屏管理列表
export const screenAdListTableConfig = [
  { prop: "platform_code", label: "监管编号" },
  { prop: "name", label: "监管名称" },
  { prop: "user_name", label: "负责人" },
  { prop: "mobile", label: "联系方式" },
  { prop: "status_parse", label: "状态" },
  { prop: "supervise_name", label: "关联群组" },
  { prop: "created_at_parse", label: "创建时间" },
];
//信发管理——信发屏管理列表
export const screenAdStatisticsTableBtn = [
  {
    label: "编辑",
    statusList: [],
  },
  {
    label: "复制",
    statusList: [],
  },
  {
    label: "平台设置",
    statusList: [],
  },
];

//信发管理——核销统计列表按钮
export const codePayStatisticsTableBtn = [
  {
    label: "查看明细",
    statusList: [],
  },
];

//信发管理——素材模板列表查询
export const templateListQueryConditions = {
  timeSelectList: [
    {
      filterRuleFormValue: "timeQueryType",
      beginTime: "begin_time",
      endTime: "end_time",
      format: "timestamp",
    },
  ],
  filterList: [
    {
      name: "模板名称",
      filterRuleFormValue: "keyword",
      width: "320px",
    },
  ],
};

//信发管理——素材模板列表
export const templateListTableConfig = [
  { prop: "name", label: "模板名称" },
  { prop: "relate_name", label: "关联素材" },
  { prop: "updated_at_parse", label: "更新时间" },
  { prop: "remark", label: "备注" },
];
//信发管理—素材模板按钮
export const templateStatisticsTableBtn = [
  {
    label: "编辑",
    statusList: [],
  },
  {
    label: "查看详情",
    statusList: [],
  },
  {
    label: "下发",
    statusList: [],
  },
];

//信发管理——素材列表查询
export const materialListQueryConditions = {
  timeSelectList: [
    {
      filterRuleFormValue: "timeQueryType",
      beginTime: "begin_time",
      endTime: "end_time",
      format: "timestamp",
    },
  ],
  filterList: [
    {
      name: "素材名称",
      filterRuleFormValue: "keyword",
      width: "320px",
    },
  ],
};

//信发管理——素材列表
export const materialListTableConfig = [
  { prop: "name", label: "素材名称" },
  { prop: "resource_type_parse", label: "素材类型" },
  { prop: "updated_at_parse", label: "更新时间" },
  { prop: "remark", label: "备注" },
];

//信发管理—核销统计列表按钮
export const materialStatisticsTableBtn = [
  {
    label: "编辑",
    statusList: [],
  },
  {
    label: "查看详情",
    statusList: [],
  },
];
