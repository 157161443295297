import request from "@/utils/request";

//监管平台
export function superviseListServe(data) {
  return request({
    url: "/api/supervise/account",
    method: "post",
    data,
  });
}

//市场群组
export function marketGroupServe(data) {
  return request({
    url: "/api/supervise/main",
    method: "post",
    data,
  });
}
